var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.display)?_c('div',{staticClass:"fade-away-message-container rounded-lg elevation-1 d-inline-flex justify-space-between align-center",class:{
    show: _vm.showClass,
    hide: !_vm.showClass,
    variation1: _vm.showVariation1Class,
    variation2: _vm.showVariation2Class,
    variation3: _vm.showVariation3Class,
    variation4: _vm.showVariation4Class,
    variation5: _vm.showVariation5Class,
  },style:({
    top: _vm.top + '%',
    'min-height': _vm.header && _vm.message ? '100px' : '60px',
    width: _vm.width + 'px',
  })},[(_vm.displayType == 'variation2')?_c('div',{staticClass:"icon-container pa-2 d-flex"},[_c('div',{staticClass:"left-line",class:_vm.background}),_c('v-icon',{staticClass:"icon",class:_vm.background},[_vm._v(_vm._s(_vm.mdiIcon))])],1):_c('div',{staticClass:"icon-container pa-2",class:_vm.background},[_c('v-icon',{staticClass:"icon mx-0"},[_vm._v(_vm._s(_vm.mdiIcon))])],1),_c('div',{staticClass:"message-container pa-2"},[_c('div',{staticClass:"title-message-wrapper"},[(_vm.header)?_c('div',{class:_vm.headerClasses},[_vm._v(_vm._s(_vm.header))]):_vm._e(),(_vm.message)?_c('div',{staticClass:"message",staticStyle:{"color":"black","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()])]),_c('div',{staticClass:"close-container pa-2 d-flex align-center justify-center"},[_c('v-btn',{attrs:{"id":"fadeawayclose","fab":"","small":"","text":""},on:{"click":_vm.clickClose}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }